/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($){

    var getCookie = function(name){
        var b = document.cookie.match('(^|[^;]+)\\s*'+name+'\\s*=\\s*([^;]+)');
        return b ? b.pop() : "";
    }

    var deleteCookie = function(name){
        this.setCookie(name, "", -1);
    }

    var setCookie = function(name, value, expireHours, path){
        var d = new Date();

        d.setTime(d.getTime()+(expireHours * 60 * 60 * 1000));

        var expires = "expires="+d.toUTCString();
        var cpath = path ? "; path="+path : "";

        window.document.cookie = name+"="+value+"; "+expires+cpath;
    }

    function addQueryArg(url, key, value){
        var pos = url.indexOf("?");
        var kvp = pos > -1 ? url.substr(pos + 1).split("&") : [];
        var i = kvp.length;
        var x;
        var query;

        key = encodeURI(key);
        value = false !== value && ""+value+"" ? encodeURI(value) : false;

        while (i--) {
            x = kvp[i].split('=');

            if (x[0] === key) {
                if (false !== value) {
                    x[1] = value;
                    kvp[i] = x.join('=');
                } else {
                    kvp.splice(i, 1);
                }

                break;
            }
        }

        if (i < 0 && false !== value) {
            kvp[kvp.length] = [key,value].join('=');
        }

        query = kvp.length ? "?"+kvp.join("&") : "";

        return pos > -1 ? url.substr(0, pos)+query : query;
    }

    $.fn.reverse = $.fn.reverse || [].reverse;

    var comboboxChosen = function(direction){
        var select = this; // obiekt jQuery
        var val = select.val();
        var selectNext = false;
        var selectPrev = false;

        if ('prev' === direction) {
            selectPrev = select.attr("data-select-prev") ? $("select[name='"+select.attr("data-select-prev")+"']") : false;
            selectNext = select.attr("data-select-next") ? $("select[name='"+select.attr("data-select-next")+"']") : false;

            if (selectPrev) {
                selectPrev.prop('disabled', true).removeClass('current-select')
                    .siblings("[data-combobox='"+selectPrev.attr('name')+"']").removeClass("combobox-wrapper-current");

                select.prop("disabled", false).addClass("current-select")
                    .siblings("[data-combobox='"+select.attr('name')+"']").addClass("combobox-wrapper-current")
                    .find('input[type="text"]').val('').trigger('autocompletereset');
            } else {
                select.prop("disabled", false).addClass("current-select")
                    .siblings("[data-combobox='"+select.attr('name')+"']").addClass("combobox-wrapper-current")
                    .find('input[type="text"]').val('').trigger('autocompletereset');
            }

            if (selectNext) {
                selectNext.prop('disabled', true).removeClass("current-select")
                    .siblings("[data-combobox='"+selectNext.attr('name')+"']").removeClass("combobox-wrapper-current");
            }

        } else {
            selectNext = select.attr('data-select-next') ? $('select[name="'+select.attr('data-select-next')+'"]') : false;

            select.parent().find('span[data-combobox-choice="'+select.attr('name')+'"]').text(
                select.children('option:selected').text()
            ).addClass('combobox-choice-visible');

            if (selectNext) {
                selectNext.children('option').not(':first-child').remove();
                action = select.attr("data-action") ? select.attr("data-action") : "get_"+selectNext.attr("name")+"_list";

                $.ajax(abnConfig.ajaxUrl, {
                    method:'post',
                    data: {
                        action: action,
                        set: val,
                    },
                    success: function(re){
                        var i = 0;

                        if (re.data.options && re.data.options.length) {
                            for (;i < re.data.options.length;++i) {
                                selectNext.append('<option value="'+re.data.options[i]+'">'+re.data.options[i]+'</option>');
                            }

                            select.removeClass('current-select')
                                .siblings('[data-combobox="'+select.attr('name')+'"]').removeClass('combobox-wrapper-current');

                            if (selectNext) {
                                selectNext.prop('disabled', false).addClass('current-select')
                                    .siblings('[data-combobox="'+selectNext.attr('name')+'"]').addClass('combobox-wrapper-current');
                            }
                        }
                    }
                });
            }

        }
    };

    var closeChoice = function(){
        var $this = $(this);
        var attr = this.getAttribute('data-combobox-choice');
        var select = $('select[name="'+attr+'"]');

        select.val('')
            .siblings('[data-combobox="'+attr+'"]').find('input[type="text"]').val('').trigger('autocompletereset');

        $('span[data-combobox-choice="'+select.attr('name')+'"]').text('').removeClass('combobox-choice-visible');
        comboboxChosen.apply(select, ['prev']);
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function(){
                $('[data-combobox-choice]').on('click', function(evt){
                    var $this = $(this),
                        newHref = window.location.href,
                        select = $('select[name="'+this.getAttribute('data-combobox-choice')+'"]'),
                        n;

                    if (! $this.text().length) {
                        return;
                    }

                    n = $this.nextAll('[data-combobox-choice]');

                    if (n.length) {
                        n.reverse().each(function(){
                            if ($(this).text().length) {
                                closeChoice.apply(this);
                                newHref = addQueryArg(newHref, this.getAttribute("data-combobox-choice"), false);
                            }
                        });
                    }

                    closeChoice.apply(this);
                    newHref = addQueryArg(newHref, this.getAttribute("data-combobox-choice"), false);

                    if (select.attr("data-reload")) {
                        setTimeout(function(){
                            window.location = newHref;
                        }, 100);
                    }
                });

                $('[data-autocomplete-choice]').on('click', function(evt){
                    var $this = $(this),
                        newHref = window.location.href,
                        input = $('input[data-autocomplete="'+this.getAttribute('data-autocomplete-choice')+'"]'),
                        names = input.attr("data-rel-fields").split(","), n;

                    if (!$this.text().length) {
                        return;
                    }

                    $this.text("").removeClass("autocomplete-choice-visible");
                    input.val("").prop("disabled", false).removeClass("autocomplete-input-hidden");

                    for (n = 0; n < names.length; ++n) {
                        $("input[name='"+names[n]+"']").val("");
                        newHref = addQueryArg(newHref, names[n], false);
                    }

                    if (input.attr("data-reload")) {
                        setTimeout(function(){
                            window.location = newHref;
                        }, 100);
                    }
                });

                var currentSort = false;

                $('select').each(function(){
                    var $this = $(this),
                        numberOfOptions = $(this).children('option').length;

                    if (this.hasAttribute('data-select') && this.getAttribute('data-select') === 'combobox') {

                        $this.combobox({
                            select: function(evt, ui){

                                if ($this.attr("data-reload")) {
                                    window.location = addQueryArg(window.location.href, $this.attr("name"), $this.val());
                                } else {
                                    comboboxChosen.apply($this, ["next"]);
                                }
                            },
                            create: function(evt, ui){

                                if ($this.hasClass('combobox-select-current')) {
                                    $this.siblings('[data-combobox="'+$this.attr('name')+'"]').addClass('combobox-wrapper-current');
                                }

                                if ($this.val().length) {
                                    comboboxChosen.apply($this);
                                }
                            }
                        });

                    } else {

                        $this.addClass('select-hidden');
                        $this.wrap('<div class="select"></div>');
                        $this.after('<div class="select-styled"></div>');

                        var $styledSelect = $this.next('div.select-styled');
                        $styledSelect.text($this.children('option:selected').eq(0).text());

                        var options = $this.children('option');
                        var value = $this.val();
                        var option, li;

                        var $list = $('<ul />', {
                            'class': 'select-options'
                        }).insertAfter($styledSelect);

                        for (var i = 0; i < numberOfOptions; i++) {
                            option = options.eq(i);

                            li = $('<li />', {
                                text: option.text(),
                                rel: option.attr("value"),
                                "class": value === option.attr("value") ? "current-option" : null,
                            }).appendTo($list);

                            if (option.attr("data-exclude")) {
                                li.attr("data-exclude", option.attr("data-exclude"));
                            }

                            if (value === option.attr("value")) {
                                currentSort = value;
                            }
                        }

                        var $listItems = $list.children('li');

                        $styledSelect.click(function(evt){
                            evt.stopPropagation();

                            $('div.select-styled.active').not(this).each(function(){
                                $(this).removeClass('active').next('ul.select-options').hide();
                            });

                            $(this).toggleClass('active').next('ul.select-options').toggle();
                        });

                        $(document).click(function(){
                            $styledSelect.removeClass('active');
                            $list.hide();
                        });

                        $listItems.click(function(evt){
                            var loc, excl, i;

                            evt.stopPropagation();

                            $styledSelect.text($(this).text()).removeClass('active');
                            $this.val($(this).attr('rel'));
                            $list.hide();

                            if ($this.attr("data-reload")) {
                                loc = window.location.href;
                                excl = $(this).attr("data-exclude") ? $(this).attr("data-exclude").split(",") : [];

                                for (i = 0; i < excl.length; ++i) {
                                    loc = addQueryArg(loc, excl[i], false);
                                }

                                window.setTimeout(function(){
                                    window.location = addQueryArg(loc, $this.attr("name"), $this.val());
                                }, 100);
                            }
                        });

                        if ("filter" === $this.attr("data-select")) {

                            $listItems.click(function(e){
                                window.location = $this.children("option[value='"+this.getAttribute("rel")+"']").attr("data-url");
                            });

                        }

                    }
                });

                /* location = gmina | quarter = miejscowość */
                var quarter = $("input[name='quarter'][type='hidden']");
                var quarterSet = quarter.length > 0 ? quarter.val().length > 0 : false;

                var location = $("input[name='location'][type='hidden']");
                var locationSet = location.length > 0 ? location.val().length > 0 : false;

                var anySet = quarter || location;

                // wspomagacze
                var createDisplayName = function(attr, names){
                    var first = names[0];
                    var spliceIndex = 1;
                    var i, displayNames;

                    if (! first) {
                        first = names[1];
                        ++spliceIndex;
                    }

                    displayNames = [];

                    for (i = spliceIndex - 1; i < names.length; ++i) {
                        if (names[i] && names[i].length > 0) {
                            displayNames.push(names[i]);
                        }
                    }

                    if (! displayNames.length) {
                        return "";
                    }

                    return "location-search" === attr ?
                                first+" ("+displayNames.splice(spliceIndex).join(", ")+")" :
                                displayNames.join(" - ")
                };

                var parseResponse = function(attr, response){
                    if (!response || !response.success || !response.data || !response.data.length) {
                        return [{
                            label: "Brak wyników",
                            value: {},
                        }];
                    }

                    var items = response.data;
                    var i = 0;
                    var parsed = [];
                    var fields, autocomplete, key, item;

                    for(; i < items.length; ++i) {
                        item = items[i];

                        autocomplete = [];
                        fields = {};

                        for (key in item) {
                            if (item.hasOwnProperty(key)) {
                                autocomplete.push(item[key]);
                                fields[key] = item[key];
                            }
                        }

                        key = item[name];

                        parsed.push({
                            label: createDisplayName(attr, autocomplete),
                            value: fields
                        });
                    }

                    return parsed;
                };

                $("[data-autocomplete='quarter-search'],[data-autocomplete='location-search']").each(function(){
                    var $this = $(this);
                    var action = $this.attr("data-action") ? $this.attr("data-action") : false;
                    var name = $this.attr("name");
                    var attr = this.getAttribute("data-autocomplete");

                    if (!action) {
                        return;
                    }

                    var Cache = {};

                    $this.on("keydown", function(evt){
                        if (evt.keyCode === $.ui.keyCode.TAB && $this.autocomplete("instance").menu.active) {
                            evt.preventDefault();
                        }

                        if (evt.keyCode === $.ui.keyCode.ENTER) {
                            evt.preventDefault();
                        }
                    }).autocomplete({
                        // autoFocus: true,
                        minLength: 0,
                        position: {
                            at: "left bottom",
                            my: "left top",
                            collision: "flip"
                        },
                        create: function(){
                            var names = this.getAttribute("data-rel-fields").split(","),
                                input = $(this),
                                choice = input.parent().find("span[data-autocomplete-choice='"+this.getAttribute("data-autocomplete")+"'][data-rel='#"+this.id+"']"),
                                n = 0,
                                vals = [],
                                disable = false,
                                v;

                            for (; n < names.length; ++n) {
                                v = $("input[name='"+names[n]+"']").val();

                                if (v.length > 0) {
                                    vals.push(v);
                                }
                            }

                            this.value = createDisplayName(attr, vals);
                            this.setAttribute("data-selected", "0");

                            if (anySet) {
                                if ("location-search" === attr && quarterSet) {
                                    disable = true;
                                }

                                if ("quarter-search" === attr && locationSet && !quarterSet) {
                                    disable = true;
                                }
                            }

                            if (disable) {
                                input.prop("disabled", true).val("")

								if (input.attr("data-disabled-placeholder")) {
									input.data("placeholderCache", input.attr("placeholder"));
									input.attr("placeholder", input.attr("data-disabled-placeholder"));
								}
                            } else {
                                if (this.value.length) {
                                    input.prop("disabled", true).addClass("autocomplete-input-hidden");
                                    $("[data-autoload-choice='"+input.attr("data-autocomplete")+"']").text(this.value).addClass("autocomplete-choice-visible");
                                    choice.text(this.value).addClass("autocomplete-choice-visible");
                                } else {
                                    input.prop("disabled", false).removeClass("autocomplete-input-hidden");

									if (input.attr("data-disabled-placeholder") && input.data("placeholderCache")) {
										input.attr("placeholder", input.data("placeholderCache"));
										input.data("placeholderCache", "");
									}

                                    choice.text("").removeClass("autocomplete-choice-visible");
                                }
                            }
                        },
                        source: function(request, response){
                            var term = request.term;

                            if (Cache[term]) {
                                response(Cache[term]);
                                return;
                            }

                            var data = {
                                action: action,
                                search: request.term
                            };

                            $.ajax({
                                url: abnConfig.ajaxUrl,
                                method: "post",
                                data: data,
                                success: function(data){
                                    var parsed = parseResponse(attr, data);

                                    Cache[term] = parsed;
                                    response(parsed);
                                },
                            });
                        },
                        open: function(evt, ui){
                            var autocomplete = $this.data("ui-autocomplete"),
                                term = autocomplete.term.toLowerCase(),
                                li = autocomplete.menu.element.find('li'),
                                itemArr, i, ii, stl, intro;

                            for (i = 0; i < li.length; ++i) {
                                itemArr = li.eq(i).text().split(" - ");

                                for (ii = 0; ii < itemArr.length; ++ii) {
                                    stl = itemArr[ii].toLowerCase();
                                    intro = stl.substr(0, term.length);

                                    if (term === intro) {
                                        itemArr[ii] = "<strong>"+itemArr[ii].substr(0, term.length)+"</strong>"+itemArr[ii].substr(term.length);
                                    }
                                }

                                li.eq(i).html(itemArr.join(" - "));
                            }
                        },
                        focus: function(evt, ui){
                            evt.preventDefault();
                            return false;
                        },
                        select: function(evt, ui){
                            var item = ui.item,
                                input = $(this),
                                names = this.getAttribute("data-rel-fields").split(","),
                                choice = input.parent().find("span[data-autocomplete-choice='"+this.getAttribute("data-autocomplete")+"']"),
                                newHref = window.location.href,
                                count = 0,
                                i, n, prop;

                            for (i = 0; i < names.length; ++i) {
                                prop = names[i];

                                n = typeof ui.item.value[prop] === "undefined" ? "" : item.value[prop];

                                $("input[name='"+prop+"'][data-rel='"+this.id+"']").val(n);

                                newHref = addQueryArg(newHref, prop, n);

                                if (typeof ui.item.value[prop] !== "undefined") {
                                    ++count;
                                }
                            }

                            if (count > 0) {
                                this.value = ui.item.label;
                                input.prop("disabled", true).addClass("autocomplete-input-hidden");
                                choice.text(this.value).addClass("autocomplete-choice-visible");
                            } else {
                                this.value = "";
                                input.prop("disabled", false).removeClass("autocomplete-input-hidden");
                                choice.text("").removeClass("autocomplete-choice-visible");
                            }

                            if (this.hasAttribute("data-reload") && this.getAttribute("data-reload") === "1") {
                                window.location = newHref;
                            }

                            return false;
                        },
                        focus: function(evt, ui){
                            this.value = ui.item.label;
                            return false;
                        }
                    }).on("click", function(){
                        $(this).autocomplete("search");
                    }).data("ui-autocomplete")._renderItem = function(ul, item){
                        return $("<li>")
                            .data("value", item.value)
                            .attr("data-value", item.label)
                            .append(item.label)
                            .appendTo(ul);
                    };
                });

                $("input[data-reload='1']").each(function(){
                    $.data(this, 'prev-val', this.value);
                }).on("blur", function(){
                    var reload = this.hasAttribute("data-reload") && this.getAttribute("data-reload") === "1";
                    var selected = this.hasAttribute("data-selected") ?
                                    this.getAttribute("data-selected") === "1" :
                                    true;

                    if (reload && selected) {
                        if ($.data(this, "prev-val") !== this.value) {
                            window.location = addQueryArg(window.location.href, this.name, this.value);
                        }
                    } else {
                        this.value = $.data(this, 'prev-val');
                    }
                });

                // extend text button

                $.fn.extend({
                    toggleText: function(a, b){
                        return this.text(this.text() == b ? a : b);
                    }
                });

                var changeBtn = $('.extend-p-show');
                changeBtn.on('click', function(){
                    $('.extend-p').slideToggle(500);
                    changeBtn.toggleText('Rozwiń', 'Zwiń');
                    
                });

                // end extend text button


            },
            finalize: function(){
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
            }
        },
        'page_template_template_advanced_search': {
            init: function(){
                setCookie("prev_href", window.location.href, 1);
            },
            finalize: function(){}
        },
        // dodawanie oferty
        'page_template_template_add_property': {
            init: function() {
                $('[data-add-property-form]').on('submit', function(evt){
                    var $this = $(this);
                    var data = {};

                    evt.preventDefault();

                    $(':input', this).each(function(){
                        if (this.type && this.type.toLowerCase() === 'checkbox') {
                            if ($(this).prop('checked')) {
                                data[this.name] = this.value;
                            }
                        } else {
                            data[this.name] = $(this).val();
                        }
                    });

                    $.ajax(abnConfig.ajaxUrl, {
                        method:'post',
                        data: data,
                        success: function(re){
                            $('[data-submit-modal]').modal().find('[data-submit-error]').text(re.data.message);

                            if (re.success) {
                                $this.get(0).reset();
                            }
                        }
                    });
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
