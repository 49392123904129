(function($){
    "use strict";

    $.widget("custom.combobox", {
        _create: function(){
            this.wrapper = $("<span>")
                .addClass("combobox-wrapper")
                .attr('data-combobox', this.element.attr('name'))
                .insertAfter(this.element);

            this.element.hide();
            this._createAutocomplete();
        },

        _createAutocomplete: function(){
            var selected = this.element.children(":selected"),
                value = selected.val() ? selected.text() : "";

            this.input = $("<input>")
                .attr('type', 'text')
                .val(value)
                .addClass("combobox-search")
                .autocomplete({
                    delay: 0,
                    minLength: 0,
                    position: {at: "left bottom", my: "left top", collision: "flip"},
                    source: $.proxy(this, "_source")
                })
                .focus(function(){
                    var $t = $(this);
                    $t.autocomplete("instance").search($t.val());
                })
                .appendTo(this.wrapper);

            if (this.element.attr('data-placeholder')) {
                this.input.attr('placeholder', this.element.attr('data-placeholder'));
            }

            if (value.length) {
                this.wrapper.addClass('combobox-has-value');
            }

            this._on(this.input, {
                autocompleteselect: function(event, ui){
                    ui.item.option.selected = true;
                    this.wrapper.addClass('combobox-has-value');
                    this._trigger("select", event, {
                        item: ui.item.option
                    });
                },

                autocompletechange: "_removeIfInvalid",
                autocompletereset: "_resetField",
            });
        },

        _source: function(request, response){
            var matcher = new RegExp('^'+$.ui.autocomplete.escapeRegex(request.term), "i");

            response(this.element.children("option").map(function(){
                var text = $(this).text();

                if (this.value && (!request.term || matcher.test(text))) {
                    return {
                        label: text,
                        value: text,
                        option: this
                    };
                }
            }));
        },

        _removeIfInvalid: function(event, ui){
            // Selected an item, nothing to do
            if (ui.item) {
                return;
            }

            // Search for a match (case-insensitive)
            var value = this.input.val(),
                valueLowerCase = value.toLowerCase(),
                valid = false;

            this.element.children("option").each(function(){
                var txt = $(this).text().toLowerCase();

                if (txt.length && valueLowerCase.indexOf(txt) > -1) {
                    this.selected = valid = true;
                    return false;
                }
            });

            // Found a match, nothing to do
            if (valid) {
                return;
            }

            this._resetField();
        },

        _resetField: function(){
            // Remove invalid value
            this.input.val("");
            this.element.val("");
            this.wrapper.removeClass('combobox-has-value');

            this.input.autocomplete("instance").term = "";
        },

        _destroy: function(){
            this.wrapper.remove();
            this.element.show();
        }
    });

})(jQuery);
